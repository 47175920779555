import { Card, Grid } from '@mui/material'
import Select from 'Components/Ui-Kits/Inputs/Select'
import { Margin } from 'Components/Ui-Kits/Margin'
import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { Colors } from 'constants/colors'
import { useAuctions } from 'data/auctions'
import { useMissingInfoProductsForAuction } from 'data/productDynamic'
import { useAppTranslation } from 'i18n/hooks'
import { useMemo } from 'react'
import { useQuery } from 'utils/useQuery'
import Table from '../Table'
import { reverse } from 'lodash'
import dayjs from 'dayjs'
import { useSetQuery } from 'utils/useSetQuery'

export const NoInformation = () => {
  const { t } = useAppTranslation()
  const { auctions, loading } = useAuctions()
  const auctionOptions = useMemo(
    () => auctions.map(({ id, auctionId }) => ({ value: id, title: `${auctionId}` })),
    [auctions]
  )
  const { auctionId: auction } = useQuery()
  const auctionId = useMemo(() => {
    if (auction) return auction

    return reverse([...auctions]).find(({ startDate }) => startDate && dayjs(startDate.toDate()).diff(dayjs()) > 0)?.id
  }, [auction, auctions])
  const [products, productsLoading] = useMissingInfoProductsForAuction(auctionId)
  const setQuery = useSetQuery()

  const productData = useMemo(() => {
    if (!products) return []

    return products.map(item => ({
      ...item,
      owner: item.seller
        ? [item.seller.holmastoId, `${item.seller.firstName} ${item.seller.lastName}`].join(' - ')
        : t(item.owner),
    }))
  }, [products, t])

  const onAuctionChange = e => {
    setQuery({
      auctionId: e.target.value,
    })
  }

  if (loading) return null

  return (
    <>
      <Margin t={5} b={5}>
        <Grid container>
          <Grid xs={12} md={6}>
            {!loading && (
              <Select
                label={t('auctions')}
                hasSearch
                displayNone={false}
                options={auctionOptions}
                value={auctionId}
                onChange={onAuctionChange}
              />
            )}
          </Grid>
        </Grid>
      </Margin>
      <Card className={'card_spacing grid_box'} style={{ width: '100%' }}>
        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <Text className={'grid_title'} type={TextTypes.BODY_20} color={Colors.BLACK}>
            {t('noInformation')}
          </Text>
          {!productsLoading && <Table data={productData} />}
        </div>
      </Card>
    </>
  )
}
